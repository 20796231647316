var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      ref: "scmenu",
      staticClass: "sc-menu",
      style: "height:calc( 100% - " + _vm.offsetTop + "px )",
    },
    [
      _vm._l(_vm.dataList, function (item, index) {
        return [
          !item[_vm.replaceFields.children]
            ? _c(
                "scMenuItem",
                {
                  key: item[_vm.replaceFields.key],
                  attrs: {
                    "menu-data": item,
                    indent: _vm.indent,
                    inlevel: _vm.inlevel,
                    "in-index": index + 1,
                    "selected-key": _vm.selectedKeyInner,
                    "selected-item": _vm.selectedItem,
                  },
                  on: {
                    "update:selectedKey": function ($event) {
                      _vm.selectedKeyInner = $event
                    },
                    "update:selected-key": function ($event) {
                      _vm.selectedKeyInner = $event
                    },
                    "update:selectedItem": function ($event) {
                      _vm.selectedItem = $event
                    },
                    "update:selected-item": function ($event) {
                      _vm.selectedItem = $event
                    },
                  },
                },
                [
                  _vm._t("menuItem", null, {
                    data: {
                      info: item,
                      path:
                        "" +
                        (_vm.inPath == ""
                          ? index + 1
                          : _vm.inPath + "-" + (index + 1)),
                    },
                  }),
                ],
                2
              )
            : _vm._e(),
          item[_vm.replaceFields.children]
            ? _c("scSubMenu", {
                key: item[_vm.replaceFields.key],
                attrs: {
                  "menu-data": item,
                  indent: _vm.indent,
                  inlevel: _vm.inlevel,
                  "in-index": index + 1,
                  "in-path":
                    "" +
                    (_vm.inPath == ""
                      ? index + 1
                      : _vm.inPath + "-" + (index + 1)),
                  "selected-key": _vm.selectedKeyInner,
                  "selected-item": _vm.selectedItem,
                },
                on: {
                  "update:selectedKey": function ($event) {
                    _vm.selectedKeyInner = $event
                  },
                  "update:selected-key": function ($event) {
                    _vm.selectedKeyInner = $event
                  },
                  "update:selectedItem": function ($event) {
                    _vm.selectedItem = $event
                  },
                  "update:selected-item": function ($event) {
                    _vm.selectedItem = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.$scopedSlots, function (ind, name) {
                      return {
                        key: name,
                        fn: function (data) {
                          return [_vm._t(name, null, null, data)]
                        },
                      }
                    }),
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }